import React, { FunctionComponent, ReactComponentElement, useEffect, useState } from 'react';
import styles from './statement.module.scss';
import {
    CreditAnnotationTransaction,
    StatementItem as StatementItemModel,
    Transaction,
    TransactionTypeEnum,
} from '../../../../types/Statement';
import {
    formatAmount,
    getAbsoluteAmount,
    getExchangeCommissionTitle,
    getTitleCreditAnnotation,
    getTitleExchange,
    isToday,
    isYesterday,
} from '../../../../utils';
import ArrowUp from '../../../../assets/images/icons/arrow_upward.svg';
import ArrowDown from '../../../../assets/images/icons/arrow_downward.svg';
import ArrowLeft from '../../../../assets/images/icons/arrow-left.svg';
import Swap from '../../../../assets/images/icons/swap.svg';
import { Amount } from '../../../../types/Amount';
import { TransactionDetail } from './transaction-detail';
import {
    clearStatement, fetchSelectedTransactionById,
    fetchSelectedTransactionDetails,
    fetchStatement,
    getSelectedTransaction,
    getStatement,
    getStatementTotal,
    openAddCreditAnnotation,
} from '../../../../store/statementSlice';
import { FloatingActionButton } from '../../../utils/floating-action-button';
import Add from '../../../../assets/images/icons/add.svg';
import { getSelectedClient } from '../../../../store/clientSlice';
import { Prevent } from '../../../utils/prevent';
import { RoleEnum } from '../../../../types/User';
import { Button } from '../../../utils/button';
import { useAppDispatch } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

interface BasicTransactionProps {
    icon: string;
    title: string;
    amount: Amount;
    active: boolean;
    secondAmount?: Amount;
    onSelection: Function;
}

const BasicTransaction: FunctionComponent<BasicTransactionProps> = props => {
    const hasSecondAmountStyle = !!props.secondAmount ? styles.hasSecondAmount : '';
    const activeStyle = props.active ? styles.active : '';
    const handleClick = () => props.onSelection();

    return (
        <div className={`${styles.transactionItem} ${activeStyle}`} onClick={handleClick}>
            <div className={styles.icon}>
                <img src={props.icon} alt="arrow"/>
            </div>
            <div className={`${styles.info} ${hasSecondAmountStyle}`}>
                <span>{props.title}</span>
                <span>{formatAmount(props.amount)}</span>
                {props.secondAmount && <span>{formatAmount(props.secondAmount)}</span>}
            </div>
        </div>
    );
};

export const Statement = () => {
    const dispatch = useAppDispatch();
    const statement = useSelector(getStatement);
    const totalStatement = useSelector(getStatementTotal);
    const client = useSelector(getSelectedClient);
    const [detailIsOpened, setDetailIsOpened] = useState(false);
    const hasMoreTransactions = statement.length < totalStatement;

    const navigate = useNavigate();

    const { typeTransaction, statementId } = useParams();
    const transactionType =
        !!typeTransaction ? Number(typeTransaction) as TransactionTypeEnum : null;

    useEffect(() => {
        dispatch(fetchStatement(client?.id));

        return () => {
            dispatch(clearStatement());
        };
    }, [client, dispatch]);

    useEffect(() => {
        if(totalStatement === 0 || !statementId || !transactionType){
            console.log('should fall here at least once')
            return;
        }

        const transactionStatementIsLoaded = statement.some(x => x.transactions.some(x => x.id === statementId && x.type === transactionType));

        if(transactionStatementIsLoaded)
            return;

        if(!hasMoreTransactions){
            navigate(`/clients/${client!.id}/statement`);
            return;
        }

        dispatch(fetchStatement(client!.id));
    }, [client, dispatch, transactionType, statementId, statement, totalStatement, navigate, hasMoreTransactions]);

    useEffect(() => {
        if (!transactionType || !statementId)
            return;

        dispatch(fetchSelectedTransactionById(transactionType, statementId as string));
    }, [transactionType, statementId, dispatch]);

    useEffect(() => {
        if(!!statementId || !!typeTransaction || !statement.length || !statement[0].transactions.length)
            return;

        const { id, type } = statement[0].transactions[0];

        navigate(`${type}/${id}`);
    }, [typeTransaction, statementId, statement, navigate]);

    const getBasicTransactionProps = (item: Transaction): { active: boolean; onSelection: Function } => {
        const active = item.id === statementId && item.type === transactionType;
        const onSelection = () => {
            setDetailIsOpened(true);
            navigate(`/clients/${client!.id}/statement/${item.type}/${item.id}`);
        };
        return { active, onSelection };
    };

    const renderCreditAnnotation = (item: CreditAnnotationTransaction) => {
        const basicProps = getBasicTransactionProps(item);
        const title = getTitleCreditAnnotation(item);

        const amount = getAbsoluteAmount(item);
        const icon = item.amount.value > 0 ? ArrowDown : ArrowUp;

        return <BasicTransaction key={item.id} {...basicProps} icon={icon} title={title} amount={amount}/>;
    };

    const renderTransactions = (item: Transaction) => {
        const basicProps = getBasicTransactionProps(item);

        if (item.type === TransactionTypeEnum.payment) {
            const valueIsPositive = item.amount.value > 0;

            const amount: Amount = {
                coin: item.amount.coin,
                value: Math.abs(item.amount.value),
            };

            if (valueIsPositive)
                return <BasicTransaction key={item.id} {...basicProps} icon={ArrowDown} title="Payment received" amount={amount}/>;

            return <BasicTransaction key={item.id} {...basicProps} icon={ArrowUp} title="Payment" amount={amount}/>;
        }

        if (item.type === TransactionTypeEnum.deposit)
            return <BasicTransaction key={item.id} {...basicProps} icon={ArrowDown} title="Deposit" amount={item.amount}/>;

        if (item.type === TransactionTypeEnum.depositNotice)
            return <BasicTransaction key={item.id} {...basicProps} icon={ArrowDown} title="Deposit Notice" amount={item.amount}/>;

        if (item.type === TransactionTypeEnum.withdraw)
            return <BasicTransaction key={item.id} {...basicProps} icon={ArrowUp} title="Withdraw" amount={item.amount}/>;

        if (item.type === TransactionTypeEnum.exchange)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={Swap}
                    title={getTitleExchange(item)}
                    amount={item.targetAmount}
                    secondAmount={item.sourceAmount}
                />
            );

        if (item.type === TransactionTypeEnum.commission)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={ArrowDown}
                    title={getExchangeCommissionTitle(item)}
                    amount={item.amount}
                />
            );

        if (item.type === TransactionTypeEnum.bookExchange)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={Swap}
                    title="Book Exchange"
                    amount={item.targetAmount}
                    secondAmount={item.sourceAmount}
                />
            );

        if (item.type === TransactionTypeEnum.creditAnnotation)
            return renderCreditAnnotation(item);

        return <div>This is an error, please show to the developer</div>;
    };

    const renderDays = (item: StatementItemModel, index: number) => {
        let label = item.date.toLocaleDateString();

        if (isToday(item.date))
            label = 'Today';

        if (isYesterday(item.date))
            label = 'Yesterday';

        return (
            <div key={index}>
                <span className={styles.title}>{label}</span>
                <div className={styles.transactions}>{item.transactions.map(renderTransactions)}</div>
            </div>
        );
    };

    const renderComponent = (child: ReactComponentElement<any>) => (
        <div className={styles.clientStatement}>
            {child}
            <Prevent authorized={RoleEnum.admin}>
                <FloatingActionButton onClick={() => dispatch(openAddCreditAnnotation())} icon={Add}/>
            </Prevent>
        </div>
    );

    if (!totalStatement)
        return renderComponent(<span>No transactions found for this client</span>);

    return renderComponent(
        <div className={`${styles.content} ${detailIsOpened ? styles.detailIsOpened : ''}`}>
            <div className={styles.lstStatement}>
                {statement.map(renderDays)}
                {hasMoreTransactions && (
                    <Button
                        label="More"
                        onClick={() => dispatch(fetchStatement(client!.id))}
                        appearance="outline"/>)}
            </div>
            <div className={styles.detailWrapper}>
                <div className={styles.backButton} onClick={() => setDetailIsOpened(false)}>
                    <img src={ArrowLeft} alt="Back Button"/>
                </div>
                <TransactionDetail/>
            </div>
        </div>,
    );
};
