import { Amount } from "./Amount";
import { BasicClient, BasicUser } from "./index";

export enum OriginDepositEnum {
    depositNoticeGrootbit = 7,
    gerenciaNet = 15,
    blockCypher = 29,
    etherScan = 41,
    bancoDoBrasil = 57,
    tron = 115,
    withdrawGrootbit = 231
}

export interface Deposit {
    id: string;
    client: BasicClient;
    externalIdentifier: string;
    origin: OriginDepositEnum;
    amount: Amount;
    createdAt: Date;
}

export enum DepositNoticeStatusEnum {
    Pending = 1,
    Doing = 2,
    Approved = 3,
    Denied = 4
}

export interface DepositNoticeHistory {
    amount: number;
    status: DepositNoticeStatusEnum;
    operator?: BasicUser;
    date: Date;
}

export interface DepositNotice extends Deposit {
    status: DepositNoticeStatusEnum;
    operator?: BasicUser;
    imageId: string;
    history: DepositNoticeHistory[];
}
