import { Decimal } from 'decimal.js';
import React, { FunctionComponent } from 'react';
import { getSelectedClient } from '../../../../../store/clientSlice';
import { getSelectedTransaction, TransactionDetail as ITransactionDetail } from '../../../../../store/statementSlice';
import { WithData } from '../../../../../types';
import { Amount } from '../../../../../types/Amount';
import { CoinEnum } from '../../../../../types/Coin';
import { CreditAnnotation } from '../../../../../types/CreditAnnotation';
import { Deposit, DepositNotice } from '../../../../../types/Deposit';
import { Exchange } from '../../../../../types/Exchange';
import { BookTypeEnum, OrderExchange, OrderStatusEnum } from '../../../../../types/Order';
import { Payment } from '../../../../../types/Payment';
import { TransactionTypeEnum } from '../../../../../types/Statement';
import { Withdraw } from '../../../../../types/Withdraw';
import {
    formatAmount,
    formatAmountCreditAnnotation, formatDepositOrigin,
    formatSupplier,
    getFullName,
    getTitleCreditAnnotation,
    getTitleExchange,
    getTitleOrderExchange,
    getWalletInfo,
    labelDepositNoticeStatus,
    labelWithdrawStatus,
} from '../../../../../utils';
import { Label } from '../../../../utils/label';
import styles from './transaction-detail.module.scss';
import { useSelector } from 'react-redux';
import { getCoinInfo } from '../../../../../utils/constants';

interface LabelItem {
    title: string;
    value: string;
}

interface Props {
    title: string;
    labels: LabelItem[];
    children?: React.ReactNode;
}

const BasicTransactionDetail: FunctionComponent<Props> = (props) => {
    const renderLabel = (item: LabelItem, index: number) =>
        <Label key={index} text={item.title} content={item.value}/>;

    return (
        <div className={styles.detailBody}>
            <h2 className={styles.title}>{props.title}</h2>
            <div className={styles.info}>
                {props.labels.map(renderLabel)}
            </div>
            {props.children}
        </div>
    );
};

const WithdrawDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Withdraw;
    const wallet = getWalletInfo(transaction.wallet);
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'External Identifier', value: transaction.externalIdentifier || '--' },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'Fee', value: formatAmount(transaction.fee) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
        { title: wallet.title, value: wallet.label },
        { title: 'Status', value: labelWithdrawStatus(transaction) },
    ];

    return <BasicTransactionDetail title="Withdraw" labels={labels}/>;
};

const DepositDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Deposit;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'External Identifier', value: transaction.externalIdentifier },
        { title: 'Origin', value: formatDepositOrigin(transaction.origin) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title="Deposit" labels={labels}/>;
};

const PaymentDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Payment;
    const currentClient = useSelector(getSelectedClient);
    const title = currentClient?.id === transaction.debtorClient.id ? 'Payment' : 'Payment Received';

    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id! },
        { title: 'From', value: getFullName(transaction.debtorClient) },
        { title: 'To', value: getFullName(transaction.creditorClient) },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title={title} labels={labels}/>;
};

const DepositNoticeDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as DepositNotice;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'Status', value: labelDepositNoticeStatus(transaction) },
        {
            title: 'Status Date',
            value: transaction.history.find(x => x.status === transaction.status)!.date.toLocaleString(),
        },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title="Deposit Notice" labels={labels}/>;
};

const CreditAnnotationDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as CreditAnnotation;

    const title = getTitleCreditAnnotation(transaction);
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'Motive', value: transaction.motive },
        { title: 'Amount', value: formatAmountCreditAnnotation(transaction) },
        { title: 'Created by', value: getFullName(transaction.operator) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title={title} labels={labels}/>;
};

const ExchangeDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Exchange;

    const title = getTitleExchange(transaction);

    const labels: LabelItem[] = [
        { title: 'Amount', value: formatAmount(transaction.targetAmount) },
        { title: 'Price', value: formatAmount(transaction.sourceAmount) },
        !!transaction.fee && { title: 'Profit', value: formatAmount(transaction.fee) },
        !!transaction.commission && { title: 'Commission Amount', value: formatAmount(transaction.commission.amount) },
        !!transaction.commission && { title: 'Commission Referral Code', value: transaction.commission.referralCode },
        { title: 'Created At', value: transaction.createdAt.toLocaleString() },
    ].filter(x => !!x);

    return <BasicTransactionDetail title={title} labels={labels}>
        {transaction.executedOnSuppliers?.length && transaction.executedOnSuppliers.map(x => (
            <>
                <div className={styles.info}>
                    <h4>{formatSupplier(x.supplier)}</h4>
                    <Label text="Source" content={formatAmount(x.source)}/>
                    <Label text="Target" content={formatAmount(x.target)}/>
                </div>
            </>))
        }
    </BasicTransactionDetail>;
};

const ExchangeCommissionDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Exchange;

    const title = `${getCoinInfo(transaction.commission.amount.coin)!.label} Commission`;

    const labels: LabelItem[] = [
        { title: 'Commission Amount', value: formatAmount(transaction.commission.amount) },
        { title: 'Commission Referral Code', value: transaction.commission.referralCode },
        { title: 'Exchange Amount', value: formatAmount(transaction.targetAmount) },
        { title: 'Exchange  Price', value: formatAmount(transaction.sourceAmount) },
        !!transaction.fee && { title: 'Exchange Profit', value: formatAmount(transaction.fee) },
        { title: 'Created At', value: transaction.createdAt.toLocaleString() },
    ].filter(x => !!x);

    return <BasicTransactionDetail title={title} labels={labels}>
        {transaction.executedOnSuppliers?.length && transaction.executedOnSuppliers.map(x => (
            <>
                <div className={styles.info}>
                    <h4>{formatSupplier(x.supplier)}</h4>
                    <Label text="Source" content={formatAmount(x.source)}/>
                    <Label text="Target" content={formatAmount(x.target)}/>
                </div>
            </>))
        }
    </BasicTransactionDetail>;
};

const BookExchangeDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as OrderExchange;

    const title = getTitleOrderExchange(transaction);

    const labels: LabelItem[] = [
        { title: 'Paid out', value: formatAmount(transaction.source) },
        { title: 'Amount received', value: formatAmount(transaction.target) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    const dict = {
        [BookTypeEnum.usdcReal]: { from: CoinEnum.usdc, to: CoinEnum.brl },
        [BookTypeEnum.btcUsdc]: { from: CoinEnum.btc, to: CoinEnum.usdc },
        [BookTypeEnum.btcReal]: { from: CoinEnum.btc, to: CoinEnum.brl },
    }[transaction.order.market];

    if (!dict)
        return <div>Book Type not found</div>;

    const pricePerCoin: Amount = {
        coin: dict.to,
        value: transaction.order.pricePerCoin,
    };

    const quantity: Amount = {
        coin: dict.from,
        value: transaction.order.quantity,
    };

    const total: Amount = {
        coin: dict.to,
        value: new Decimal(transaction.order.pricePerCoin).mul(transaction.order.quantity).toNumber(),
    };

    const labelStatus = {
        [OrderStatusEnum.open]: 'Open',
        [OrderStatusEnum.completed]: 'Completed',
        [OrderStatusEnum.canceled]: 'Canceled',
    }[transaction.order.status];

    if (!labelStatus)
        return <div>Order label status not found</div>;

    return <BasicTransactionDetail title={title} labels={labels}>
        <>
            <h4>Order Details</h4>
            <div className={styles.info}>
                <Label text="Price per coin" content={formatAmount(pricePerCoin)}/>
                <Label text="Quantity" content={formatAmount(quantity)}/>
                <Label text="Total" content={formatAmount(total)}/>
                <Label text="Status" content={labelStatus}/>
                <Label text="Created at" content={transaction.order.createdAt.toLocaleString()}/>
                <Label text="Closed at" content={transaction.order.closedAt?.toLocaleString()}/>
            </div>
        </>
    </BasicTransactionDetail>;
};

const detailOptions = [
    { type: TransactionTypeEnum.withdraw, Component: WithdrawDetail },
    { type: TransactionTypeEnum.deposit, Component: DepositDetail },
    { type: TransactionTypeEnum.payment, Component: PaymentDetail },
    { type: TransactionTypeEnum.depositNotice, Component: DepositNoticeDetail },
    { type: TransactionTypeEnum.exchange, Component: ExchangeDetail },
    { type: TransactionTypeEnum.creditAnnotation, Component: CreditAnnotationDetail },
    { type: TransactionTypeEnum.bookExchange, Component: BookExchangeDetail },
    { type: TransactionTypeEnum.commission, Component: ExchangeCommissionDetail },
];

export const TransactionDetail = () => {
    const selectedTransaction = useSelector(getSelectedTransaction);

    if (!selectedTransaction)
        return <div>Oddly, no transaction loaded. Please call the beautiful developer</div>;

    const { Component } = detailOptions.find(x => x.type === selectedTransaction.type)!;
    return <div className={styles.transactionDetail}>
        <Component data={selectedTransaction.transaction}/>
    </div>;
};
