import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchClient, getSelectedClient, unselectClient } from '../../../store/clientSlice';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { getFullName } from '../../../utils';
import { ClientProfile } from './client-profile';
import styles from './client.module.scss';
import { ClientDetails } from './client-details/clientDetails';
import { ChangeClientStatus } from '../ChangeClientStatus';
import { ChangeClientPurchaseFee } from '../change-client-purchase-fee';
import { AddCreditAnnotation } from '../add-credit-annotation';
import { ClientEdit } from "./client-edit";
import { ClientAddress } from "./client-address";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";

export const Client = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const client = useSelector(getSelectedClient);

    useEffect(() => {
        dispatch(fetchClient(id!));

        return () => {
            dispatch(unselectClient());
        };
    }, [id, dispatch]);

    if (!client)
        return <div>loading...</div>;

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Clients', link: '/clients' },
        { label: getFullName(client) }
    ];

    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <div className={styles.clientBody}>
                <ClientProfile client={client}/>
                <ClientDetails client={client}/>
            </div>
            <ChangeClientStatus/>
            <ChangeClientPurchaseFee/>
            <AddCreditAnnotation/>
            <ClientAddress/>
            <ClientEdit/>
        </div>
    );
};
