import { isSameDay } from 'date-fns';
import Decimal from 'decimal.js';
import { staticPix } from 'pix-qrcode';
import { BasicPerson, HasId, PaginationParams } from '../types';
import { Amount } from '../types/Amount';
import { Client, ClientTypeEnum } from '../types/Client';
import { CoinEnum } from '../types/Coin';
import { CreditAnnotation } from '../types/CreditAnnotation';
import { DepositNotice, DepositNoticeHistory, DepositNoticeStatusEnum, OriginDepositEnum } from '../types/Deposit';
import { Exchange, Supplier } from '../types/Exchange';
import { OrderExchange, OrderTypeEnum } from '../types/Order';
import { CreditAnnotationTransaction, CommissionTransaction, ExchangeTransaction } from '../types/Statement';
import { RoleEnum, User } from '../types/User';
import { PaperWallet, PixKeyTypeEnum, WithdrawWallet } from '../types/Wallet';
import { Withdraw, WithdrawHistoryEntry, WithdrawStatusEnum } from '../types/Withdraw';
import { getCoinInfo } from './constants';

export const generateUuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const isEmailValid = (email: string) => !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const numberFormat = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 20 });

export const formatSupplier = (supplier: Supplier): string => {
    const supplierName: Record<Supplier, string> = {
        [Supplier.grootbit]: 'Grootbit',
        [Supplier.binance]: 'Binance',
        [Supplier.escada]: 'Escada',
        [Supplier.awesome]: 'Awesome',
        [Supplier.travelex]: 'Travelex',
        [Supplier.okcoin]: 'Okcoin',
    };

    return supplierName[supplier];
};

export const formatAmount = (amount: Amount | null | undefined): string => {
    if(!amount)
        return '';

    return `${getCoinInfo(amount.coin).symbol} ${numberFormat.format(amount.value)}`;
};

export const formatValueAmount = (amount: Amount): string => numberFormat.format(amount.value);

export const formatPrice = (coin: CoinEnum, amount: number): string => formatAmount({ coin, value: amount });

export const add = (first: number, lastNumber: number): number => {
    const result = new Decimal(first).add(lastNumber);
    return Number(result);
};

export const getFullName = (obj: BasicPerson | Client | null | undefined): string => {
    if (!obj) return '';

    return `${obj.firstName} ${obj.lastName}`;
};

const withdrawStatusOptions = new Map<WithdrawStatusEnum, (obj: Withdraw | WithdrawHistoryEntry) => string>();
withdrawStatusOptions.set(WithdrawStatusEnum.pending, () => 'Pending');
withdrawStatusOptions.set(WithdrawStatusEnum.doing, ({ operator }) => `Assigned to ${getFullName(operator)}`);
withdrawStatusOptions.set(
    WithdrawStatusEnum.done,
    obj => `Done by ${getFullName(obj.operator)} at ${obj.createdAt.toLocaleString()}`,
);

export const labelWithdrawStatus = (obj: Withdraw | WithdrawHistoryEntry): string =>
    withdrawStatusOptions.get(obj.status)!(obj);

export const labelDepositNoticeStatus = (obj: DepositNotice | DepositNoticeHistory): string => {
    const labelDepositNoticeStatusOptions = {
        [DepositNoticeStatusEnum.Pending]: 'Pending',
        [DepositNoticeStatusEnum.Denied]: `Denied by  ${getFullName(obj.operator)}`,
        [DepositNoticeStatusEnum.Doing]: `Assigned to ${getFullName(obj.operator)}`,
        [DepositNoticeStatusEnum.Approved]: `Approved by ${getFullName(obj.operator)}`,
    };
    return labelDepositNoticeStatusOptions[obj.status];
};

const getLabelExchange = (isSale: boolean, obj: Exchange | ExchangeTransaction): string =>
    isSale
        ? `${getCoinInfo(obj.sourceAmount.coin)!.label} Sale`
        : `${getCoinInfo(obj.targetAmount.coin)!.label} Purchase`;

export const getTitleExchange = (obj: Exchange | ExchangeTransaction): string =>
    getLabelExchange(obj.targetAmount.coin === CoinEnum.brl, obj);

export const getExchangeCommissionTitle = (obj: CommissionTransaction): string =>
    `${getCoinInfo(obj.amount.coin)!.label} Commission`;

const getLabelOrderExchange = (isSale: boolean, obj: OrderExchange): string =>
    isSale
        ? `${getCoinInfo(obj.source.coin)!.label} Sale`
        : `${getCoinInfo(obj.target.coin)!.label} Purchase`;

export const getTitleOrderExchange = (obj: OrderExchange): string =>
    getLabelOrderExchange(obj.order.orderType === OrderTypeEnum.sell, obj);

export const getTitleCreditAnnotation = (obj: CreditAnnotation | CreditAnnotationTransaction): string =>
    obj.amount.value > 0 ? 'Manual Deposit' : 'Manual Withdraw';

export const getAbsoluteAmount = <T extends { amount: Amount }>(obj: T): Amount => ({
    coin: obj.amount.coin,
    value: Math.abs(obj.amount.value),
});

export const formatAmountCreditAnnotation = (obj: CreditAnnotation | CreditAnnotationTransaction): string =>
    formatAmount(getAbsoluteAmount(obj));

export const cpfFormatter = (cpf: string | null | undefined): string => {
    if (!cpf)
        return '';

    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
};

export const cnpjFormatter = (cnpj: string | null | undefined): string => {
    if (!cnpj)
        return '';

    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(-2)}`;
};

export const phoneFormatter = (phoneNumber: string): string => {
    const num = phoneNumber.replace(/^55/, '');
    return `(${num.slice(0, 2)}) ${num.slice(2, 3)} ${num.slice(3, 7)}-${num.slice(-4)}`;
};


export const labelKeyPix = new Map<PixKeyTypeEnum, string>();
labelKeyPix.set(PixKeyTypeEnum.cpf, 'CPF');
labelKeyPix.set(PixKeyTypeEnum.cnpj, 'CNPJ');
labelKeyPix.set(PixKeyTypeEnum.email, 'Email');
labelKeyPix.set(PixKeyTypeEnum.phoneNumber, 'Phone');
labelKeyPix.set(PixKeyTypeEnum.random, 'Random');

const pixFormatter = (formatter: (val: string) => string = x => x) => (wallet: PaperWallet): string =>
    `${labelKeyPix.get(wallet.key.keyType)}: ${formatter(wallet.key.value)}`;

export const pixFormatters = new Map<PixKeyTypeEnum, (wallet: PaperWallet) => string>();
pixFormatters.set(PixKeyTypeEnum.email, pixFormatter());
pixFormatters.set(PixKeyTypeEnum.random, pixFormatter());
pixFormatters.set(PixKeyTypeEnum.cpf, pixFormatter(cpfFormatter));
pixFormatters.set(PixKeyTypeEnum.cnpj, pixFormatter(cnpjFormatter));
pixFormatters.set(PixKeyTypeEnum.phoneNumber, pixFormatter(phoneFormatter));

const documentFormatters = [
    { type: ClientTypeEnum.individual, label: 'CPF', formatter: cpfFormatter },
    { type: ClientTypeEnum.business, label: 'CNPJ', formatter: cnpjFormatter },
];


export const documentFormatter = (client: Client): { title: string; formatted: string } => {
    const document = documentFormatters.find(x => x.type === client.clientType)!;
    return {
        title: document.label,
        formatted: document.formatter(client.document?.number),
    };
};

export const getWalletInfo = (wallet: WithdrawWallet): { title: string; label: string; value: string } => {
    if (wallet.coin === CoinEnum.brl)
        return {
            title: 'Pix Key',
            label: pixFormatters.get(wallet.key.keyType)!(wallet),
            value: wallet.key.value,
        };
    const labelCoin = getCoinInfo(wallet.coin).label;
    return {
        title: !wallet.alias ? `${labelCoin} Wallet` : wallet.alias,
        label: wallet.key,
        value: wallet.key,
    };
};

export const getUrlGenerator = (baseUrl: string | undefined) => (...args: (string | number | Date | object)[]) => {
    const params = args
        .map(param => {
            if(param instanceof Date)
                return param.toISOString();

            if (typeof param === 'object') {
                const query = Object.keys(param)
                    .map(x => {
                        const val = (param as any)[x]
                        if(val instanceof Date)
                            return [x, val.toISOString()]
                        return [x, val]
                    })
                    .map(([x, val]) => `${x}=${val}`)
                    .join('&');

                return `?${query}`;
            }

            return param;
        })
        .join('/');

    return `${baseUrl}/${params}`;
};

export const generateQrCode = async (withdraw: Withdraw): Promise<string> => {
    if (withdraw.wallet.coin !== CoinEnum.brl) return '';

    const pixQrCode = await staticPix({
        pixKey: withdraw.wallet.key.value,
        description: 'Grootbit Withdraw',
        merchant: getFullName(withdraw.client),
        merchantCity: 'Sao Paulo',
        transactionId: withdraw.id.slice(-20),
        amount: withdraw.amount.value.toFixed(2),
    });

    return pixQrCode.qrcode;
};

export const basicPagination: PaginationParams = {
    pageIndex: 0,
    pageSize: 10,
};

export const oneDay = 1000 * 60 * 60 * 24;
export const isToday = (dt: Date): boolean => isSameDay(dt, new Date());
export const isYesterday = (dt: Date): boolean => isSameDay(dt, new Date(Date.now() - oneDay));

export const isAuthorized = (user: User, authorizedRoles: RoleEnum[]): boolean =>
    authorizedRoles.some(x => x === user.role);

export const delay = (time: number): Promise<void> => new Promise<void>(resolve => setTimeout(resolve, time));

export const updateOnList = <T extends HasId>(lst: T[], obj: T): T[] => {
    const position = lst.map(x => x.id).indexOf(obj.id);

    if (position === -1)
        return lst;

    const newList = lst.filter(x => x.id !== obj.id);
    newList.splice(position, 0, obj);

    return newList;
};

export const upsertOnList = <T extends HasId>(lst: T[], obj: T, atTheEnd: boolean = false): T[] => {
    const position = lst.map(x => x.id).indexOf(obj.id);

    if(position === -1 && atTheEnd)
        return [...lst, obj];

    if (position === -1 && !atTheEnd)
        return [obj, ...lst];

    const newList = lst.filter(x => x.id !== obj.id);
    newList.splice(position, 0, obj);

    return newList;
};

export const sortBackwardsList = <T>(lst: T[]): T[] => {
    const newArray = Array.from({length: lst.length});

    for (let x = 0; x < lst.length; x++){
        const position = lst.length - x - 1;
        newArray[position] = lst[x];
    }

    return newArray as T[];
}

export const formatSimpleDate = (value: Date): string => {
    const language = navigator.language;

    const today = new Date();
    const isToday = value.getDate() === today.getDate() &&
        value.getMonth() === today.getMonth() &&
        value.getFullYear() === today.getFullYear();

    if (isToday)
        return value.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" });

    const isSameYear = value.getFullYear() === today.getFullYear();

    if (isSameYear)
        return value.toLocaleTimeString(language, {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        });

    return value.toLocaleTimeString(language, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
};

const origins: Record<OriginDepositEnum, string> = {
    [OriginDepositEnum.depositNoticeGrootbit]: 'Deposit Notice',
    [OriginDepositEnum.gerenciaNet]: 'GerenciaNet',
    [OriginDepositEnum.blockCypher]: 'BlockCypher',
    [OriginDepositEnum.etherScan]: 'EtherScan',
    [OriginDepositEnum.bancoDoBrasil]: 'Banco do Brasil',
    [OriginDepositEnum.tron]: 'Tron',
    [OriginDepositEnum.withdrawGrootbit]: 'Withdraw Grootbit',
}

export const formatDepositOrigin = (origin: OriginDepositEnum): string =>
    origins[origin];
